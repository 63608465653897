import {ResetCSS} from 'common/assets/css/style';
import {DrawerProvider} from 'common/contexts/DrawerContext';
import {theme} from 'common/theme/appCreative2';
import Seo from 'components/seo';
import {ContentWrapper, GlobalStyle} from 'containers/AppCreative2/appCreative2.style';
// import Banner from 'containers/AppCreative2/Banner';
import Navbar from 'containers/AppCreative2/NavbarOthers';
import React, {Fragment} from 'react';
import Sticky from 'react-stickynode';
import {ThemeProvider} from 'styled-components';

import {Modal} from '@redq/reuse-modal';
import '@redq/reuse-modal/es/index.css';
import Footer from 'containers/WebApp/Footer';
// import {TextWrapper} from "../containers/AppCreative/ChooseUs/chooseUs.style";
// import Text from "../common/components/Text";

import {graphql} from "gatsby"
import Heading from "../common/components/Heading";
import SectionWrapper from "../containers/AppCreative/Faq/faq.style";
import Container from "../common/components/UI/Container";
import CookieConsent from "react-cookie-consent";

export default function Template({
                                     data, // this prop will be injected by the GraphQL query below.
                                 }) {
    const {markdownRemark} = data // data.markdownRemark holds our post data
    const {frontmatter, html} = markdownRemark
    return (
        <ThemeProvider theme={theme}>
            <Fragment>
                <Seo title="Fortune Mine Games | Legal"/>
                <Modal/>
                <ResetCSS/>
                <GlobalStyle/>
                <ContentWrapper className="content">
                    <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                        <DrawerProvider>
                            <Navbar/>
                        </DrawerProvider>
                    </Sticky>
                    <SectionWrapper>
                        <Container>
                            <div className="content">
                                <Heading content={frontmatter.title}/>
                                <h2>{frontmatter.date}</h2>
                                <div
                                    className="blog-post-content"
                                    dangerouslySetInnerHTML={{__html: html}}
                                />
                            </div>
                        </Container>
                    </SectionWrapper>
                    <Footer/>
                    <CookieConsent
                        location="bottom"
                        buttonText="Okay"
                        //declineButtonText="Decline"
                        cookieName="gatsby-gdpr-google-analytics">
                        We use necessary cookies to make our site work.
                    </CookieConsent>
                </ContentWrapper>
            </Fragment>
        </ThemeProvider>
    )
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        title
      }
    }
  }
`
